<template>
  <div class="app-error">
    <transition name="fade" v-if="error">
      <p class="app-error__value">{{error}}</p>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    // show: {
    //   type: Boolean,
    //   default: true
    // },

    error: {
      type: String,
      default: null
    }
  },

  data: () => ({})
};
</script>

<style lang="scss" scoped>
.app-error {
  &__value {
    margin-top: 1rem;

    color: #d4584a;
    text-align: center;
    font-size: 0.95rem;
  }
}
</style>
