import { AuthenticationError, UserService } from "@/services/user.service";

import { GetSMSService } from "../services/dashboard.services";
import { TokenService } from "@/services/storage.service";
import { connectSocket } from "@/socket";
import router from "../router";

const state = {
  authenticating: false,
  registration: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: "",
  registrationErrorCode: 0,
  registrationError: "",
  resetErrorCode: 0,
  resetError: "",
  changePassErrorCode: 0,
  changePassError: "",
  user: "",
  userInfo: {},
  refreshTokenPromise: null // Holds the promise of the refresh token
};

const getters = {
  loggedIn: state => {
    return state.accessToken ? true : false;
  },

  authenticationErrorCode: state => {
    return state.authenticationErrorCode;
  },

  authenticationError: state => {
    return state.authenticationError;
  },

  authenticating: state => {
    return state.authenticating;
  },

  registrationErrorCode: state => {
    return state.registrationErrorCode;
  },

  userInfo: state => {
    return state.userInfo;
  }
};

const actions = {
  async login({ commit }, { user, cb, cbError }) {
    // commit("loginRequest");

    console.log("login");

    try {
      // commit("setEmail", user);
      const token = await UserService.login(user);
      const userInfo = await GetSMSService.getUserInfo();

      console.log(token);

      commit("loginSuccess", token);
      commit("setUserInfo", userInfo);

      connectSocket();

      cb();

      // Redirect the user to the page he first tried to visit or to the home view
      router.push(router.history.current.query.redirect || "/");

      // return true;
    } catch (e) {
      console.log(e.response.data);

      cbError(e.response.data);
      // if (e instanceof AuthenticationError) {
      //   commit("loginError", {
      //     errorCode: e.errorCode,
      //     errorMessage: e.message
      //   });
      // }
      // return false;
    }
  },

  async register({ commit }, user) {
    commit("clearRegError");
    try {
      const resReg = await UserService.register(user);

      // Redirect the user to the page he first tried to visit or to the login view
      // router.push(router.history.current.query.redirect || '/login')

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit("registerError", {
          errorCode: e.errorCode,
          errorMessage: e.message
        });
      }
    }
  },

  async resetPassword({ commit }, user) {
    try {
      const reset = await UserService.reset(user);

      // Redirect the user to the page he first tried to visit or to the login view
      // router.push(router.history.current.query.redirect || '/login')

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit("resetError", {
          errorCode: e.errorCode,
          errorMessage: e.message
        });
      }
    }
  },

  async changePassword({ commit }, data) {
    try {
      const change = await UserService.change(data);

      // Redirect the user to the page he first tried to visit or to the login view
      // router.push(router.history.current.query.redirect || '/login')

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit("changePassError", {
          errorCode: e.errorCode,
          errorMessage: e.message
        });
      }
    }
  },

  async confirmEmail({ commit }, { activationToken, cb, cbError }) {
    console.log("activationToken", activationToken);

    try {
      const resConfirm = await UserService.confirmEmail(activationToken);
      cb();

      // return true;
    } catch (e) {
      cbError(Object.values(e.message)[0][0]);

      // if (e instanceof AuthenticationError) {
      //   commit("registerError", {
      //     errorCode: e.errorCode,
      //     errorMessage: e.message
      //   });
      // }
      // return false;
    }
  },

  logout({ commit }) {
    UserService.logout();
    commit("logoutSuccess");
    router.push("/login");
  },

  refreshToken({ commit, state }) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller
    if (!state.refreshTokenPromise) {
      const p = UserService.refreshToken();
      commit("refreshTokenPromise", p);

      // Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
      // Clear the promise on error as well.
      p.then(
        response => {
          commit("refreshTokenPromise", null);
          commit("loginSuccess", response);
        },
        error => {
          commit("refreshTokenPromise", null);
        }
      );
    }

    return state.refreshTokenPromise;
  }
};

const mutations = {
  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },

  setEmail(state, userEmail) {
    state.user = userEmail.email;
  },

  setUserInfo(state, data) {
    state.userInfo = data;
  },

  loginSuccess(state, accessToken) {
    // document.cookie = `token=${accessToken}`;
    // state.accessToken = accessToken;
    // state.authenticating = false;
  },

  loginError(state, { errorCode, errorMessage }) {
    // state.authenticating = false;
    // state.authenticationErrorCode = errorCode;
    // state.authenticationError = errorMessage;
  },

  registerError(state, { errorCode, errorMessage }) {
    state.registration = false;
    state.registrationErrorCode = errorCode;
    state.registrationError = errorMessage;
  },

  resetError(state, { errorCode, errorMessage }) {
    state.resetErrorCode = errorCode;
    state.resetError = errorMessage;
  },

  changePassError(state, { errorCode, errorMessage }) {
    state.changePassErrorCode = errorCode;
    state.changePassError = errorMessage;
  },

  clearRegError(state) {
    state.registrationErrorCode = 0;
    state.registrationError = "";
  },

  clearAuthError(state) {
    // state.authenticationErrorCode = 0;
    // state.authenticationError = "";
  },

  clearResetError(state) {
    state.resetErrorCode = 0;
    state.resetError = "";
  },

  clearChangePassError(state) {
    state.changePassErrorCode = 0;
    state.changePassError = "";
  },

  logoutSuccess(state) {
    state.accessToken = "";
  },

  refreshTokenPromise(state, promise) {
    state.refreshTokenPromise = promise;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
