// Импорт глобальных стилей проекта
import "./assets/scss/main.scss";
import "./assets/img/flag-icon-css/css/flag-icon.min.css";

import { ApiService } from "@/services/api.service";
import App from "./App.vue";
import Axios from "axios";
import Buefy from "buefy";
import ConstantsMixin from "@/mixins/global/constants";
import { GetSMSService } from "@/services/dashboard.services";
import Toasted from "vue-toasted";
import { TokenService } from "./services/storage.service";
import Transitions from "vue2-transitions";
import { UserService } from "@/services/user.service";
import VAnimateCss from "v-animate-css";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueCookies from "vue-cookies";
import VueNativeSock from "vue-native-websocket";
// import 'buefy/dist/buefy.css'
import Vuebar from "vuebar";
import Vuelidate from "vuelidate";
import { connectSocket } from "./socket";
import dateFilter from "@/filters/date.filters";
import ifvisible from "ifvisible.js";
import { requestPermissionNotification } from "./utils/pushNotification";
import router from "./router";
import store from "./store";

Vue.use(VueCookies);

Vue.use(VAnimateCss);
Vue.use(Vuelidate);
Vue.use(Vuebar);
Vue.use(Buefy);
Vue.use(Transitions);
Vue.use(VueClipboard);
Vue.use(Toasted);

// Vue.use(VueNativeSock, "wss://api.smsplaza.io/ws/events/", {
//   store: store,
//   format: "json",
//   pingTimeout: 60000
// });

Vue.prototype.$http = Axios;

// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader();

  UserService.refreshToken().then(() => {
    connectSocket();

    GetSMSService.getUserInfo().then(response => {
      store.commit("auth/setUserInfo", response);
    });

    // UserService.startUserSession();
  });
}

ApiService.mount401Interceptor();

Vue.config.productionTip = false;

Vue.directive("click-outside", {
  bind(el, binding) {
    el.addEventListener("click", e => e.stopPropagation());
    document.body.addEventListener("click", binding.value);
  },
  unbind(el, binding) {
    document.body.removeEventListener("click", binding.value);
  }
});

Vue.filter("date", dateFilter);

requestPermissionNotification();

// let blur = false;

// ifvisible.setIdleDuration(10);
// ifvisible.on("idle", () => {
//   if (!blur) {
//     console.log("idle");
//     if (TokenService.getToken()) {
//       UserService.logout();
//       router.push("login");
//     }
//   }
// });
// ifvisible.on("wakeup", () => {
//   console.log("wakeup");
//   ifvisible.setIdleDuration(10);
// });

// ifvisible.on("blur", function() {
//   blur = true;
//   console.log("blur");
// });

// ifvisible.on("focus", function() {
//   blur = false;
//   console.log("focus");
// });

new Vue({
  router,
  store,
  mixins: [ConstantsMixin],
  render: h => h(App)
}).$mount("#app");
