function requestPermissionNotification() {
  if ("Notification" in window) {
    Notification.requestPermission(function(result) {
      // $status.innerText = result;
    });
  }
}

const pushNotification = text => {
  if (!("Notification" in window)) {
    return;
  }

  try {
    var notification = new Notification(text);
  } catch (err) {
    console.log(err);
  }
};

export { requestPermissionNotification, pushNotification };
