import { cookieDomen, domen } from "@/utils/constants";

import { GetSMSService } from "../services/dashboard.services";
import { TokenService } from "./storage.service";
import { UserService } from "@/services/user.service";
import axios from "axios";
import router from "../router";
import store from "../store";

const ApiService = {
  _401interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  async setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken()}`;

    let cookieName = "token";
    let cookieValue = TokenService.getToken();
    // document.cookie =
    //   cookieName + "=" + cookieValue + "; domain = .plazalab.club";
    document.cookie =
      cookieName +
      "=" +
      cookieValue +
      `; domain = ${cookieDomen}; SameSite=None; Secure`;

    // const userInfo = await GetSMSService.getUserInfo();

    // store.commit("auth/setUserInfo", userInfo);

    // UserService.startUserSession();

    // document.cookie = cookieName + "=" + cookieValue;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (
          error.response.status == 401 &&
          router.history.current.name != "login"
        ) {
          if (error.config.url.includes(`${domen}/api/users/token/refresh/`)) {
            UserService.logout();
            router.push("/login");

            return;
          }

          // return new Promise(async (resolve, reject) => {
          try {
            let res = await store.dispatch("auth/refreshToken");

            // Retry the original request
            return this.customRequest({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data,
              params: error.config.params
            });
          } catch (e) {
            UserService.logout();
            router.push("/login");

            return;
          }
        }

        throw error;
      }
    );
  },

  // mount401Interceptor() {
  //   this._401interceptor = axios.interceptors.response.use(
  //     response => {
  //       return response;
  //     },
  //     async error => {
  //       if (
  //         error.config.url.includes(
  //           "https://api.smsplaza.io/api/users/token/refresh/"
  //         ) ||
  //         (error.request.status === 401 &&
  //           router.history.current.name != "login")
  //       ) {
  //         UserService.logout();
  //         router.push("/login");
  //         // window.location = "/login";
  //         // router.replace({
  //         //   path: "/login"
  //         // });

  //         // if (
  //         //   error.config.url.includes(
  //         //     "https://api.smsplaza.io/api/users/token/refresh/"
  //         //   )
  //         // ) {
  //         //   // Refresh token has failed. Logout the user
  //         //   await store.dispatch("auth/logout");
  //         //   throw error;
  //         // } else {
  //         //   // Refresh the access token
  //         //   try {
  //         //     await store.dispatch("auth/refreshToken");
  //         //     // Retry the original request
  //         //     return this.customRequest({
  //         //       method: error.config.method,
  //         //       url: error.config.url,
  //         //       data: error.config.data,
  //         //       params: error.config.params
  //         //     });
  //         //   } catch (e) {
  //         //     // Refresh has failed - reject the original request

  //         //     UserService.stopUserSession();
  //         //     UserService.logout();
  //         //     this.$router.push("login");
  //         //     throw error;
  //         //   }
  //         // }
  //       }

  //       // If error was not 401 just reject as is
  //       throw error;
  //       // return Promise.reject(error);
  //     }
  //   );
  // },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  }
};

export { ApiService };
