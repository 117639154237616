import { ApiService } from "./api.service";
// const BASE_URL = "https://api.plazalab.club/";
import { BASE_URL } from "@/utils/constants";
import { TokenService } from "./storage.service";

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

let timeout = null;

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_txoken
   * @throws AuthenticationError
   **/
  login: async function(data) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/token/`,
      data: data
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access);
      TokenService.saveRefreshToken(response.data.refresh);
      ApiService.setHeader();

      try {
        UserService.startUserSession();
      } catch (error) {
        console.log(error);
      }
      // NOTE: Interceptor 401 Error
      ApiService.mount401Interceptor();

      return response.data.access;
    } catch (error) {
      // console.log(error);
      // return false;
      // return response.data.access;
      throw error;
      // throw new AuthenticationError(
      //   error.response.status,
      //   error.response.data.detail
      // );
    }
  },
  register: async function(data) {
    let referral = localStorage.getItem("REFERRAL_KEY");

    if (referral) {
      data = { ...data, referral };
    }

    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/registration/`,
      data: data
      /* auth: {
        username: process.env.VUE_APP_CLIENT_ID,
        password: process.env.VUE_APP_CLIENT_SECRET
      } */
    };

    console.log(JSON.stringify(requestData.data));

    try {
      const response = await ApiService.customRequest(requestData);

      // TokenService.saveToken(response.data.access)
      // TokenService.saveRefreshToken(response.data.refresh)
      // ApiService.setHeader()

      // NOTE: Interceptor 401 Error
      // ApiService.mount401Interceptor()

      return response.data;
    } catch (error) {
      // console.log(error);
      // console.log(response.data);

      throw new AuthenticationError(error.response.status, error.response.data);
    }
  },
  /**
   * Reset password
   **/
  reset: async function(data) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/password_recovery/`,
      data
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data);
    }
  },
  /**
   * Change password
   **/
  change: async function(data) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/change_password/`,
      data
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data);
    }
  },
  /**
   * Refresh the access token.
   **/
  refreshToken: async function() {
    const refreshToken = await TokenService.getRefreshToken();

    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/token/refresh/`,
      data: {
        refresh: refreshToken
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.access);
      // TokenService.saveRefreshToken(response.data.refresh)
      // Update the header in ApiService
      ApiService.setHeader();

      return response.data.access;
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail
      );
    }
  },
  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    console.log("logout");

    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();

    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    ApiService.unmount401Interceptor();

    // UserService.stopUserSession();
  },
  async confirmEmail(activationToken) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/users/registration/confirm/`,
      data: activationToken
    };

    try {
      const response = await ApiService.customRequest(requestData);

      // TokenService.saveToken(response.data.access)
      // TokenService.saveRefreshToken(response.data.refresh)
      // Update the header in ApiService
      // ApiService.setHeader()

      return response.data;
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data);
    }
  },

  startUserSession() {
    // Vue.prototype.router.push("login");
    // let refresh = () => {

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      UserService.logout();
      window.location = "/login";
      // UserService.logout();
      // Vue.prototype.$router.push("login");
      // }, 5000);
    }, 1800000);
    // };

    // refresh();
    // document.addEventListener("log_out", refresh);
  },

  stopUserSession() {
    clearTimeout(timeout);
    // document.removeEventListener("log_out", refresh);
  }
};

export default UserService;

export { UserService, AuthenticationError };
