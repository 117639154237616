<template>
  <!-- <zoom-center-transition :delay="50" :duration="200"> -->
  <div id="number" class="card">
    <header class="card__header">
      <h1 class="card__header-title">Step 3. Number</h1>
      <div class="card__header-search">
        <input
          class="card__header-search-field"
          id="numberCardInput"
          type="text"
          v-model="numbersSearch"
          placeholder="Search"
        >
        <b-icon pack="fas" icon="search" size="is-small"></b-icon>
      </div>

      <!-- <p>{{ number }}</p> -->
    </header>

    <section class="card__body">
      <div class="card__control">
        <h2 class="card__control-title card__col_numbers">Numbers</h2>

        <!-- <span class="card__control-action"> -->
        <!-- <span class="card__control-title card__col_price">Price</span> -->

        <span class="card__control-title card__col_days" @click="sortNumber">
          <button class="card__filter">Days</button>

          <AppSortArrow class="card__arrow" :value="sort == 'days'"/>
          <!-- <b-icon
            :class="{ 'rotate': sort == 'days' }"
            pack="fas"
            icon="chevron-down"
            size="is-small"
          ></b-icon>-->
        </span>
        <!-- </span> -->
      </div>

      <div class="card__wrapper" id="numbersCard">
        <div class="card__list-container" :style="{'max-height': cardHeight}">
          <!-- <slide-y-up-transition :delay="250" :duration="450" v-show="data"> -->
          <transition-group name="fade" tag="ul" class="card__list">
            <li
              class="card__list-item card__list-item_country"
              v-for="number in data"
              :style="number.was_used_by_user && {backgroundColor: '#2B2A31'}"
              :key="number.id"
              @click="getOrder(number)"
            >
              <span class="card__col card__col_numbers">{{ number.number_str | numberDisplay}}</span>

              <!-- <span class="card__col card__col_price card__list-item-price">
                <b-tooltip
                  label="Price for 1 SMS"
                  class="custom-toltip-white"
                  position="is-left"
                  type="is-dark"
                  animated
                >
                  <span>
                    €{{ number.price.toFixed(2) }}
                    <sup>
                      <b-icon pack="fas" icon="info" size="is-small" />
                    </sup>
                  </span>
                </b-tooltip>
              </span>-->

              <span class="card__col card__col_days card__list-item-days">
                <b-tooltip
                  :label="toltipMessageNumber(number.days)"
                  :class="{'custom-toltip-green': number.days >= 21, 
                                'custom-toltip-yellow': number.days >= 7 &&  number.days < 21,
                                'custom-toltip-red': number.days <= 6}"
                  position="is-left"
                  type="is-dark"
                  animated
                  multilined
                >
                  <span
                    :class="['number-day', {
                          'green': number.days >= 21,
                          'yellow':  number.days >= 7 &&  number.days < 21, 
                          'red': number.days <= 6
                        }]"
                    @click="stopEvent"
                  >
                    {{ number.days | daysDecor }}
                    <sup>
                      <b-icon pack="fas" icon="info" size="is-small"/>
                    </sup>
                  </span>
                </b-tooltip>
              </span>
            </li>
          </transition-group>
          <!-- </slide-y-up-transition> -->
        </div>
      </div>
    </section>
    <a
      v-show="!stepmobile"
      class="card__clear_mobile"
      href="#"
      @click.prevent="clearCountryMobile"
    >Change country</a>
  </div>
  <!-- </zoom-center-transition> -->
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Inputmask from "inputmask";
import adaptivity from "../mixins/adaptiveWatcher";

import AppSortArrow from "@/components/app/root/AppSortArrow";

export default {
  name: "NumbersCard",

  components: { AppSortArrow },

  data: () => ({
    // Search query
    numbersSearch: "",
    // For sorting
    orerNumbersDay: -1,
    // For arrow animation
    rotateIconNumbers: false,
    // For card displaying and adaptive view
    heigtWindow: 920,
    stepmobile: true,
    // For emiting data
    selectedNumbers: "",
    // For watch on scroll
    scrollElem: null,

    sort: "-days"
  }),
  props: ["data", "code"],
  mixins: [adaptivity],
  watch: {
    data() {
      if (this.data.length !== 0 && this.code && !this.numbersSearch) {
        let im = new Inputmask(`${this.code}xxxx * * * *`);
        im.mask(document.getElementById("numberCardInput"));
      }
    },

    number() {
      this.$emit("load", {
        type: "search",
        number: this.number
        // sort: this.sort
      });
    }
  },
  filters: {
    daysDecor: function(value) {
      if (value >= 21) {
        return "21+";
      } else {
        return value;
      }
    },
    numberDisplay: function(value) {
      return value.replace("****", "xxxx");
    }
  },
  computed: {
    ...mapState("stepByStep", ["step3"]),

    number() {
      let numbersSearch = this.numbersSearch.trim();
      numbersSearch = numbersSearch.slice(numbersSearch.length - 8);
      numbersSearch = numbersSearch.replace(/ /g, "");
      numbersSearch = numbersSearch.replace(/_/g, "");

      return numbersSearch;
    },

    orderedDaysNumbers: function() {
      let filterdList;

      if (this.numbersSearch) {
        let numbersSearch = this.numbersSearch.trim();
        numbersSearch = numbersSearch.slice(numbersSearch.length - 8);
        numbersSearch = numbersSearch.replace(/ /g, "");
        numbersSearch = numbersSearch.replace(/_/g, "");

        filterdList = this.data.filter(function(number) {
          return number.number_str.includes(numbersSearch);
        });
      } else {
        filterdList = this.data;
      }

      let order = this.orerNumbersDay;
      let sorted = filterdList.sort(function(a, b) {
        return (a.clean_numbers - b.clean_numbers) * order;
      });

      return sorted;
    },
    cardHeight(heigtWindow) {
      if (window.innerHeight >= 900) {
        return this.heigtWindow * 0.68 + "px";
      } else {
        return this.heigtWindow * 0.59 + "px";
      }
    }
  },
  methods: {
    ...mapMutations("stepByStep", [
      "step2Activate",
      "step2Deactivate",
      "step3Activate",
      "step3Deactivate"
    ]),
    getOrder(obj) {
      this.selectedNumbers = obj;
      this.$emit("selectNumber", this.selectedNumbers);

      if (window.innerWidth <= 1024) {
        this.stepmobile = false;
      }
    },
    toltipMessageNumber(days) {
      return `This number will be available for ${
        days <= 21 ? days : "21+"
      } days`;
    },
    sortNumber() {
      // this.orerNumbersDay = this.orerNumbersDay * -1;
      // this.rotateIconNumbers = !this.rotateIconNumbers;
      this.sort = this.sort == "days" ? "-days" : "days";

      this.$emit("load", {
        type: "sort",
        number: this.number,
        sort: this.sort
      });
    },
    stopEvent(e) {
      e.stopPropagation();
    },
    clearCountryMobile() {
      this.$emit("changeCountry");

      setTimeout(() => {
        this.step3Deactivate();
      }, 200);

      setTimeout(() => {
        this.step2Activate();
      }, 400);
    }
  },
  mounted() {
    this.heigtWindow = window.innerHeight;
    window.addEventListener("resize", this.handlerWindow);

    this.heigtWindow = window.innerHeight;
    window.addEventListener("resize", this.handlerWindow);

    this.scrollElem = document.querySelector("#numbersCard");
    this.scrollElem.addEventListener("scroll", e => {
      if (
        this.scrollElem.scrollTop + this.scrollElem.clientHeight + 10 >=
          this.scrollElem.scrollHeight &&
        !this.servicesSearch
      ) {
        this.$emit("load", {
          type: "load",
          number: this.number,
          sort: this.sort
        });
      }
    });
  },
  beforeDestroy() {
    this.numbersSearch = "";
    this.step3Deactivate();
  }
};
</script>