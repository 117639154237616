<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import ifvisible from "ifvisible.js";
// @ is an alias to /src
import EmptyLayout from "@/layouts/auth/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { connectSocket } from "./socket";
import { BASE_URL } from "@/utils/constants";
import { ApiService } from "@/services/api.service";

import { UserService } from "@/services/user.service";
import { TokenService } from "@/services/storage.service";

export default {
  components: {
    EmptyLayout,
    MainLayout
  },

  data: () => ({
    time: 1800000,
    // time: 10000,
    timeout: null
  }),

  computed: {
    ...mapGetters(["socetMessage"]),
    ...mapState("auth", ["userInfo", "accessToken"]),
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    }
  },

  async mounted() {
    this.createTimeout();

    document.addEventListener("keydown", event => {
      this.createTimeout();
    });

    const body = document.getElementsByTagName("body")[0];

    body.addEventListener("touchmove", e => {
      // console.log(e);
      this.createTimeout();
    });

    body.addEventListener("mousemove", e => {
      // console.log(e);

      this.createTimeout();
    });

    // console.log("mounted");
    // ifvisible.setIdleDuration(30);
    // ifvisible.on("idle", () => {
    //   console.log("idle");
    //   if (TokenService.getToken()) {
    //     UserService.logout();
    //     this.$router.push("login");
    //   }
    // });
    // ifvisible.on("wakeup", () => {
    //   ifvisible.setIdleDuration(30);
    // });
    // let referral = this.$route.query.referral;
    // console.log("referral", referral);
    // console.log("referral111", this.$router.history.current.query);
    // console.log("referral111", this.$router);
    // if (referral) {
    //   localStorage.setItem("REFERRAL_KEY", referral);
    // const requestData = {
    //   method: "post",
    //   url: `${BASE_URL}api/users/visit_referral/`,
    //   referral: referral
    // };
    // console.log("referral", referral);
    // try {
    //   const response = await ApiService.customRequest(requestData);
    //   console.log("response", response);
    // } catch (error) {
    //   console.log(error);
    // }
    // }
    // document.tidioIdentify = {
    //   distinct_id: "unique_id", // Unique user ID in your system
    //   email: "contact_mail", // User email
    //   name: "John Doe", // Visitor name
    //   city: "Denver", // City
    //   country: "US" // Country
    // };
    // console.log("tidioIdentify", document.tidioIdentify);
    // if (TokenService.getToken()) {
    //   // ApiService.setHeader();
    //   await UserService.refreshToken();
    //   // connectSocket();
    // }
  },

  watch: {
    socetMessage() {
      if (this.socetMessage.event === "user.balance") {
        this.setUserInfo({
          ...this.userInfo,
          ...{
            balance: this.socetMessage.balance,
            loyal_points: this.socetMessage.loyal
          }
        });
      }
    },

    userInfo(data) {
      tidioChatApi.setVisitorData({
        email: data.email,
        name: data.email.split("@")[0]
      });
    }
  },

  methods: {
    ...mapMutations("auth", ["setUserInfo", "logout"]),

    createTimeout() {
      clearTimeout(this.timeout);

      if (TokenService.getToken()) {
        this.timeout = setTimeout(() => {
          UserService.logout();
          this.$router.push("login");
        }, this.time);
      }
    }
  }
  /* mounted () {
    
  },
  beforeDestroy () {
    
  } */
};
</script>


<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #18d16e;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

html {
  overflow-y: auto;
}

body,
html {
  height: 100%;
  *::-webkit-scrollbar {
    // width: 5px;
    // height: 5px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    // height: 2px;
    // margin: 0 5px;
  }
  ::-webkit-scrollbar-button {
    // background: trans;
    // background-color: #d92e8a;
  }
  ::-webkit-scrollbar-track {
    // background-color: #999;
  }
  ::-webkit-scrollbar-track-piece {
    // background-color: #ffffff;
    background: transparent;

    // width: 20px;
    // height: 20px;

    // height: 50px;
  }
  ::-webkit-scrollbar-thumb {
    width: 20px;
    height: 20px;
    // height: 50px;
    background-color: #73707a;
    border-radius: 3px;
    // height: 50px;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
    // height: 50px;
  }
  ::-webkit-resizer {
    background-color: #73707a;
    // height: 50px;
  }
}

#app {
  width: 100%;
  height: 100%;
}
</style>
