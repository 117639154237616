<template>
  <div class="auth-layout">
    <div class="auth-layout__overlay" @click="close()"></div>
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>


<script>
import { ApiService } from "@/services/api.service";

export default {
  name: "empty-layout",

  methods: {
    close() {
      if (this.$router.history.current.name == "unsubscribe")
        this.$router.push("/login");
    }
  },

  created() {
    // document.cookie = "token=;max-age=-1";
    // ApiService.removeHeader();
  }
};
</script>

