export default {
  methods: {
    handlerWindow () {
      if (window.innerWidth <= 1024) {
        this.tabletMode = true
        this.openNavbar = true

      } else {
        this.tabletMode = false
      }

      this.heigtWindow = window.innerHeight
    }
  }
}
