<template>
  <div class="app">
    <section class="app__topbar">
      <topbar @toggleNav="openNavbar = !openNavbar" v-click-outside="close"></topbar>
    </section>

    <main class="app__content">
      <aside class="app__sidebar" :class="{'app__sidebar_close': openNavbar}">
        <div class="logo-in-menu">
          <router-link to="/" class="logo">
            <img src="../assets/img/Logo.png" alt="SmsPlaza.io" />
          </router-link>
        </div>
        <nav id="nav" class="app__navigation-bar" @click="closeNav(openNavbar)">
          <a
            :class="['app__sidebar-item', { 'app__sidebar-item_active': $route.name === link.name }]"
            v-for="(link, index) in navLinks"
            :key="index"
            @click.prevent="handlerNavagate(link)"
          >
            <b-icon :pack="link.iconPack" :icon="link.icon" size="is-small"></b-icon>
            <span
              class="app__navigation-bar-close"
              :class="{'close-nav': openNavbar, 'open-nav': !openNavbar}"
            >{{link.title}}</span>
          </a>
          <!-- <router-link :to="link.url" v-for="(link, index) in navLinks" :key="index">
            <b-icon :pack="link.iconPack" :icon="link.icon" size="is-small"></b-icon>
            <span
              class="app__navigation-bar-close"
              :class="{'close-nav': openNavbar, 'open-nav': !openNavbar}"
            >{{link.title}}</span>
          </router-link>-->
        </nav>

        <div class="app__sidebar-clock" :class="{'opacity': !openNavbar}">{{ date }}</div>
        <!-- date | date('date') }} | {{ date | date('time') -->
      </aside>

      <section class="app__dynamic-content">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </section>
    </main>
  </div>
</template>

<script>
import { TokenService } from "@/services/storage.service";
import { UserService } from "@/services/user.service";

import ifvisible from "ifvisible.js";

import topbar from "@/components/Topbar.vue";
import adaptivity from "../mixins/adaptiveWatcher";

import moment from "moment-timezone";

export default {
  name: "main-layout",
  data: () => ({
    date: null,
    interval: null,
    openNavbar: false,
    tabletMode: false,
    navLinks: [
      {
        title: "Get SMS",
        name: "dashboard",
        iconPack: "fab",
        icon: "telegram-plane",
        exact: true
      },
      {
        title: "Active Orders",
        name: "orders",
        iconPack: "fas",
        icon: "shopping-cart"
      },
      {
        title: "SMS History",
        name: "history",
        iconPack: "fas",
        icon: "history"
      },
      {
        title: "Deposit",
        name: "deposit",
        iconPack: "fas",
        icon: "euro-sign"
      },
      {
        title: "Transactions",
        name: "transactions",
        iconPack: "fas",
        icon: "exchange-alt"
      },
      {
        title: "News",
        name: "news",
        iconPack: "fas",
        icon: "rss"
      },
      {
        title: "Live Chat",
        link: "https://www.tidio.com/talk/whfyongghvuz8uyheywgfvnhtwjbxwct",
        iconPack: "fas",
        icon: "envelope"
      },
      // {
      //   title: "How to use",
      //   url: "/rules",
      //   iconPack: "fas",
      //   icon: "book-open"
      // },
      {
        title: "FAQ",
        name: "faq",
        iconPack: "fas",
        icon: "question-circle"
      },
      {
        title: "Rules",
        name: "rules",
        iconPack: "fas",
        icon: "exclamation-circle"
      }
    ]
  }),
  mixins: [adaptivity],
  components: {
    topbar
  },

  created() {
    //   let blur = false;
    //   ifvisible.setIdleDuration(10);
    //   ifvisible.on("idle", () => {
    //     if (!blur) {
    //       console.log("idle");
    //       if (TokenService.getToken()) {
    //         UserService.logout();
    //         this.$router.push("login");
    //       }
    //     }
    //   });
    //   ifvisible.on("wakeup", () => {
    //     console.log("wakeup");
    //     ifvisible.setIdleDuration(10);
    //   });
    //   ifvisible.on("blur", function() {
    //     blur = true;
    //     console.log("blur");
    //   });
    //   ifvisible.on("focus", function() {
    //     blur = false;
    //     console.log("focus");
    //   });
  },

  mounted() {
    // localStorage.setItem("REFERRAL_KEY", this.$route.query.referral);
    // console.log("referral", this.$route.query.referral);
    // console.log("referralreferralreferralreferralreferralreferralreferral");

    this.changeDate();

    this.interval = setInterval(this.changeDate, 1000);

    window.addEventListener("resize", this.handlerWindow);
    if (window.innerWidth <= 1024) {
      this.openNavbar = true;
    } else {
      this.openNavbar = false;
    }
  },
  methods: {
    closeNav(openNavbar) {
      if (window.innerWidth <= 1024) {
        this.openNavbar = !openNavbar;
      } else {
        return;
      }
    },
    close() {
      // this.openNavbar = false
      if (window.innerWidth <= 1024) {
        this.openNavbar = true;
      } else {
        return;
      }
    },

    changeDate() {
      this.date = moment()
        .tz("Europe/London")
        .format("DD.MM.YYYY | HH:mm");
    },

    handlerNavagate(data) {
      if (data.name) this.$router.push({ name: data.name });
      else if (data.link) {
        window.open(data.link, "_blank");
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>


