import Vue from "vue";
import { socketDomen } from "@/utils/constants";
import store from "./store";

let interval = 0;

const connectSocket = () => {
  let socket = new WebSocket(socketDomen);

  Vue.prototype.$socket = socket;

  socket.onopen = function(e) {
    interval = 0;
  };

  socket.onmessage = function(event) {
    store.commit("SOCKET_ONMESSAGE", JSON.parse(event.data));
  };

  socket.onclose = function(event) {
    setTimeout(() => {
      interval += 1000;

      connectSocket();
    }, 1000);
  };

  socket.disconnect = function(event) {
    setTimeout(() => {
      interval += 1000;

      connectSocket();
    }, 1000);
  };

  socket.onerror = function(error) {
    console.log(`[error] ${error.message}`);

    socket.close();
  };
};

// connect();

export { connectSocket };
