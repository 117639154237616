const state = {
  step1: true,
  step2: false,
  step3: false
}

const mutations = {
  step1Activate (state) {
    state.step1 = true
  },

  step1Deactivate (state) {
    state.step1 = false
  },

  step2Activate (state) {
    state.step2 = true
  },

  step2Deactivate (state) {
    state.step2 = false
  },

  step3Activate (state) {
    state.step3 = true
  },

  step3Deactivate (state) {
    state.step3 = false
  }
}

export default {
  namespaced: true,
  state,
  // getters,
  // actions,
  mutations
}
