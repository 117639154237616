import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import auth from './auth.module'
import stepByStep from './stepByStep.module'
import socket from './socket.module'

Vue.use(Vuex)

export default new Vuex.Store({
  /* plugins: [
    createPersistedState()
  ], */
  modules: {
    auth,
    stepByStep,
    socket
  }
})
