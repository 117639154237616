import GetSms from "./views/GetSms.vue";
import Router from "vue-router";
import { TokenService } from "./services/storage.service";
import Vue from "vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "dashboard",
      meta: { layout: "main" },
      component: GetSms
      // beforeEnter: ifAuthenticated
    },
    {
      path: "/login",
      name: "login",
      meta: {
        layout: "empty",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      },
      // beforeEnter: ifNotAuthenticated,
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/auth/Login.vue")
    },
    {
      path: "/users/:activation_key/confirm_registration/",
      name: "confirm_email",
      meta: {
        layout: "empty",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      },
      component: () =>
        import(
          /* webpackChunkName: "confirm_email" */ "./views/auth/ConfirmEmail.vue"
        )
    },
    {
      path: "/reset",
      name: "reset",
      meta: {
        layout: "empty",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      },
      component: () =>
        import(/* webpackChunkName: "reset" */ "./views/auth/Reset.vue")
    },
    {
      path: "/register",
      name: "register",
      meta: {
        layout: "empty",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      },
      component: () =>
        import(/* webpackChunkName: "register" */ "./views/auth/Register.vue")
    },
    {
      path: "/unsubscribe/:code",
      name: "unsubscribe",
      meta: {
        layout: "empty",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: false
      },
      component: () =>
        import(
          /* webpackChunkName: "register" */ "./views/auth/Unsubscribe.vue"
        )
    },
    {
      path: "/orders",
      name: "orders",
      meta: { layout: "main" },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "orders" */ "./views/Orders.vue")
    },
    {
      path: "/history",
      name: "history",
      meta: { layout: "main" },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "history" */ "./views/History.vue")
    },
    {
      path: "/payment",
      name: "deposit",
      meta: { layout: "main" },
      component: () =>
        import(/* webpackChunkName: "deposit" */ "./views/Deposit.vue")
    },
    {
      path: "/transactions",
      name: "transactions",
      meta: { layout: "main" },
      component: () =>
        import(
          /* webpackChunkName: "transactions" */ "./views/Transactions.vue"
        )
    },
    {
      path: "/news",
      name: "news",
      meta: { layout: "main" },
      component: () => import(/* webpackChunkName: "news" */ "./views/News.vue")
    },
    {
      path: "/rules",
      name: "rules",
      meta: { layout: "main" },
      component: () =>
        import(/* webpackChunkName: "rules" */ "./views/Rules.vue")
    },
    {
      path: "/faq",
      name: "faq",
      meta: { layout: "main" },
      component: () =>
        import(/* webpackChunkName: "features" */ "./views/FAQ.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath } // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next("/");
  }

  next();
});

export default router;
