import { ApiService } from "./api.service";
import { BASE_URL } from "@/utils/constants";

// const BASE_URL = "https://api.plazalab.club/";

class GetSmsError extends Error {
  constructor(message, errorCode, statusText) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.errorText = statusText;
  }
}

const GetSMSService = {
  getUserInfo: async function() {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/users/my_profile/`
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  getService: async function(limit, offset) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/services/`,
      params: {
        limit: limit,
        offset: offset
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  getServiceOther: async function(limit, offset) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/services/get_other/`
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  searchService: async function(search) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/services/`,
      params: {
        search: search
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  getCountries: async function(service, limit, offset) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/countries/${service.id}/`,
      params: {
        limit: limit,
        offset: offset

        // sort: "1"
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  getNumbers: async function(services, country, limit, offset, search, sort) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/numbers/${services.id}/${country.id}/`,
      params: {
        limit: limit,
        offset: offset,
        search,
        sort
        // sort: "-1"
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  buySms: async function(data) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/storage/orders/new/`,
      data: data
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new GetSmsError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  }
};

export default GetSMSService;

export { GetSMSService, GetSmsError };
