<template>
  <zoom-center-transition :delay="50" :duration="200" v-show="step2 && stepmobile">
    <!-- && stepmobile -->
    <div id="city" class="card" v-show="step2 && stepmobile">
      <!-- && stepmobile -->
      <header class="card__header">
        <h1 class="card__header-title">Step 2. Country</h1>
        <div class="card__header-search" v-if="!selectedCountries">
          <input
            class="card__header-search-field"
            v-model="countrySearch"
            type="text"
            placeholder="Search"
          >
          <b-icon pack="fas" icon="search" size="is-small"></b-icon>
        </div>
      </header>

      <section class="card__body">
        <div class="card__control">
          <h2 class="card__control-title">Countries</h2>

          <span class="card__control-title card__col_price">Price</span>

          <span class="card__control-action card__col_count">
            <span
              class="card__control-action-col card__control-action-col_right"
              @click="sortCountry"
            >
              <button class="card__filter">Count</button>
              <!-- <p>{{ orerCountryNumber }}</p> -->
              <AppSortArrow
                class="card__arrow"
                :value="orerCountryNumber ? orerCountryNumber === 1 : null"
              />
            </span>
          </span>
        </div>

        <div class="card__wrapper" id="countriesCard">
          <!-- <pre>{{ orderedNumbersCountry }}</pre> -->
          <!-- v-bar -->
          <div
            class="card__list-container"
            :class="{'card__list-container_no-scroll': selectedCountries}"
            :style="{'max-height': cardHeight}"
          >
            <!-- <pre>{{ orderedNumbersCountry }}</pre> -->
            <!-- <p>{{ selectedCountries }}</p> -->
            <transition-group name="fade" tag="ul" class="card__list" v-if="!selectedCountries">
              <template v-for="country in orderedNumbersCountry">
                <li
                  class="card__list-item card__list-item_country"
                  v-if="country.clean_numbers"
                  :key="country.id"
                  @click.self="setCountry(country)"
                >
                  <span @click="setCountry(country)">
                    <span class="card__list-icon">
                      <span class="flag-icon" :class="`flag-icon-${country.flag}`"></span>
                    </span>
                    {{ country.name }}
                  </span>

                  <span class="card__col card__col_price card__list-item-price">
                    <b-tooltip
                      label="Price for 1 SMS"
                      class="custom-toltip-white"
                      position="is-left"
                      type="is-dark"
                      animated
                    >
                      <span>
                        €{{ country.price.toFixed(2) }}
                        <sup>
                          <b-icon pack="fas" icon="info" size="is-small"/>
                        </sup>
                      </span>
                    </b-tooltip>
                  </span>

                  <!-- <b-tooltip
                    :label="toltipMessageCountry(country.clean_numbers)"
                    position="is-left"
                    type="is-dark"
                    class="toltip-custom-country"
                    multilined
                  >-->
                  <!-- :class="{'custom-toltip-green': number.days >= 14, 
                                'custom-toltip-yellow': number.days >= 6,
                  'custom-toltip-red': number.days <= 5}"-->
                  <span class="card__col card__col_count">
                    <b-tooltip
                      :label="toltipMessageCountry(country.clean_numbers)"
                      class="custom-toltip-white"
                      position="is-left"
                      type="is-dark"
                      animated
                      multilined
                    >
                      <span>
                        {{ country.clean_numbers }}
                        <sup>
                          <b-icon pack="fas" icon="info" size="is-small"/>
                        </sup>
                      </span>
                    </b-tooltip>
                  </span>
                </li>
              </template>
            </transition-group>

            <ul class="card__list card__list_checked" v-if="selectedCountries">
              <li class="card__list-item card__list-item_country card__list-item_checked">
                <span>
                  <span class="card__list-icon">
                    <span class="flag-icon" :class="`flag-icon-${selectedCountries.flag}`"></span>
                  </span>
                  {{ selectedCountries.name }}
                </span>

                <span class="card__col card__col_price card__list-item-price">
                  <b-tooltip
                    label="Price for 1 SMS"
                    class="custom-toltip-white"
                    position="is-left"
                    type="is-dark"
                    animated
                  >
                    <span>
                      €{{ selectedCountries.price.toFixed(2) }}
                      <sup>
                        <b-icon pack="fas" icon="info" size="is-small"/>
                      </sup>
                    </span>
                  </b-tooltip>
                </span>

                <span class="card__col card__col_count-checked">
                  <b-tooltip
                    :label="toltipMessageCountry(selectedCountries.clean_numbers)"
                    position="is-left"
                    class="toltip-custom-country"
                    type="is-dark"
                    multilined
                  >
                    <span @click="stopEvent">
                      {{ selectedCountries.clean_numbers }}
                      <sup>
                        <b-icon pack="fas" icon="info" size="is-small"/>
                      </sup>
                    </span>
                  </b-tooltip>
                </span>
              </li>
            </ul>

            <a
              v-show="selectedCountries"
              class="card__clear"
              href="#"
              @click.prevent="clearCountry"
            >Change country</a>
          </div>
        </div>
      </section>
      <a
        v-show="!stepmobile"
        class="card__clear card__clear_mobile"
        href="#"
        @click.prevent="clearServiceMobile"
      >Change service</a>
    </div>
  </zoom-center-transition>
</template>

<script>
import AppSortArrow from "@/components/app/root/AppSortArrow";

import { mapState, mapMutations } from "vuex";
import adaptivity from "../mixins/adaptiveWatcher";

export default {
  name: "CountryCard",
  components: {
    AppSortArrow
  },
  data: () => ({
    // Search query
    countrySearch: "",
    // For sorting
    orerCountryNumber: -1,
    // For arrow animation
    rotateIcon: false,
    // For card displaying and adaptive view
    heigtWindow: 920,
    stepmobile: true,
    // For emiting data
    // selectedCountries: "",
    // For watch on scroll
    scrollElem: null
  }),
  props: ["data", "service", "selectedCountries"],
  mixins: [adaptivity],
  watch: {
    data() {
      if (this.data.length !== 0) {
      } else {
      }
    }
  },
  computed: {
    ...mapState("stepByStep", ["step2"]),
    orderedNumbersCountry: function() {
      let filterdList;

      if (this.countrySearch) {
        let countrySearch = this.countrySearch.toLowerCase();
        filterdList = this.data.filter(country =>
          country.name.toLowerCase().includes(countrySearch)
        );
      } else {
        filterdList = this.data;
      }

      // if (this.orerCountryNumber) {
      let order = this.orerCountryNumber;
      let sorted = filterdList.sort(function(a, b) {
        return (a.clean_numbers - b.clean_numbers) * order;
      });

      return sorted;
      // }
      // // sort()
      // // return filterdList;
      // return filterdList.sort((a, b) => {
      //   // Use toUpperCase() to ignore character casing
      //   const bandA = a.name.toUpperCase();
      //   const bandB = b.name.toUpperCase();

      //   let comparison = 0;
      //   if (bandA > bandB) {
      //     comparison = 1;
      //   } else if (bandA < bandB) {
      //     comparison = -1;
      //   }
      //   return comparison;
      // });
    },
    cardHeight() {
      if (window.innerHeight >= 900) {
        return this.heigtWindow * 0.68 + "px";
      } else {
        return this.heigtWindow * 0.59 + "px";
      }
    }
  },
  methods: {
    ...mapMutations("stepByStep", [
      "step1Activate",
      "step2Deactivate",
      "step3Activate",
      "step3Deactivate"
    ]),
    setCountry(obj) {
      // this.selectedCountries = obj;
      this.countrySearch = "";

      this.$emit("selectCountry", obj);

      if (window.innerWidth <= 1024) {
        /* setTimeout(() =>{
          this.step3Activate()
        }, 100) */

        setTimeout(() => {
          this.step2Deactivate();
        }, 200);

        setTimeout(() => {
          this.step3Activate();
        }, 400);
      } else {
        this.step3Activate();
      }
    },
    stopEvent(e) {
      e.stopPropagation();
    },
    clearCountry() {
      // this.selectedCountries = "";
      this.$emit("clear");
      this.step3Deactivate();
    },
    clearServiceMobile() {
      this.$emit("changeService");
      setTimeout(() => {
        this.step2Deactivate();
      }, 100);

      setTimeout(() => {
        this.step1Activate();
      }, 250);
    },
    toltipMessageCountry(amount) {
      return `${amount} unused numbers for ${this.service}`;
    },
    sortCountry() {
      if (this.orerCountryNumber === null) {
        this.orerCountryNumber = 1;
      } else {
        this.orerCountryNumber = this.orerCountryNumber * -1;
      }

      this.rotateIcon = !this.rotateIcon;
    }
  },
  mounted() {
    this.heigtWindow = window.innerHeight;
    window.addEventListener("resize", this.handlerWindow);

    this.scrollElem = document.querySelector("#countriesCard");
    this.scrollElem.addEventListener("scroll", e => {
      if (
        this.scrollElem.scrollTop + this.scrollElem.clientHeight + 10 >=
          this.scrollElem.scrollHeight &&
        !this.servicesSearch
      ) {
        this.$emit("load");
      }
    });
  },
  beforeDestroy() {
    // this.selectedCountries = "";
    this.step2Deactivate();
  }
};
</script>