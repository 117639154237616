import Vue from "vue";
import VueNativeSock from "vue-native-websocket";

const state = {
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false
  }
};

const getters = {
  socetMessage: state => {
    return state.socket.message;
  }
};

const mutations = {
  SOCKET_ONOPEN(state, event) {
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
  },
  SOCKET_ONCLOSE(state, event) {
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    // state.socket.message = JSON.parse(message.data);
    state.socket.message = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {},
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  }
};

const actions = {
  sendMessage: function(context, message) {
    Vue.prototype.$socket.send(message);
  },
  "order.connect_number"(context, message) {
    // console.log(context);
  }
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
};
