<template>
  <div id="services" class="card">
    <header class="card__header">
      <h1 class="card__header-title">Step 1. Service</h1>

      <div class="card__header-search" v-if="!selectedServices">
        <input
          class="card__header-search-field"
          type="text"
          v-model="servicesSearch"
          placeholder="Search"
          @input="search"
        >
        <b-icon pack="fas" icon="search" size="is-small"></b-icon>
      </div>
    </header>

    <section class="card__body">
      <div class="card__control">
        <h2 class="card__control-title">Services</h2>
      </div>

      <div class="card__wrapper" ref="servicesCard" id="servicesCard">
        <div
          class="card__list-container"
          :class="{'card__list-container_no-scroll': selectedServices}"
        >
          <transition-group
            name="fade"
            tag="ul"
            class="card__list card__list_services"
            v-if="!selectedServices"
          >
            <li
              class="card__list-item"
              v-for="service in searchServices"
              :key="service.id"
              @click="setService(service)"
            >
              <span class="card__list-icon" v-if="service.icon">
                <img :src="service.icon" :alt="service.name">
              </span>
              {{ service.name }}
            </li>
          </transition-group>

          <transition v-if="serviceOther && otherDisplaying && !selectedServices" name="fade">
            <div>
              <div class="card__other-items">
                You did not find the service that you need?
                <br>Select a service "Other" and go Get SMS!
              </div>

              <li class="card__list-item" @click="setService(serviceOther)">
                <span class="card__list-icon" v-if="serviceOther.icon">
                  <img :src="serviceOther.icon" :alt="serviceOther.name">
                </span>
                {{ serviceOther.name }}
              </li>
            </div>
          </transition>

          <ul class="card__list card__list_checked" v-if="selectedServices">
            <li class="card__list-item card__list-item card__list-item_checked">
              <span class="card__list-icon" v-if="selectedServices.icon">
                <img :src="selectedServices.icon" :alt="selectedServices.name">
              </span>
              {{ selectedServices.name }}
            </li>
          </ul>

          <a
            v-if="selectedServices"
            class="card__clear"
            href="#"
            @click.prevent="clearService"
          >Change service</a>
        </div>
      </div>
    </section>

    <ul class="card__list" v-if="!otherDisplaying && !selectedServices && serviceOther">
      <li class="card__list-item card__list-item_other" @click.self="setService(serviceOther)">
        <span class="card__list-icon" v-if="serviceOther.icon" @click="setService(serviceOther)">
          <img :src="serviceOther.icon" :alt="serviceOther.name">
        </span>
        {{ serviceOther.name }}
        <span class="card__list-info">
          <b-tooltip
            :label="tooltipText"
            class="custom-toltip-white"
            :position="innerWidth > 767 ? 'is-right' : 'is-top'"
            type="is-dark"
            animated
            multilined
          >
            <sup>
              <b-icon pack="fas" icon="info" size="is-small"></b-icon>
            </sup>
          </b-tooltip>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { GetSMSService } from "../services/dashboard.services";
import adaptivity from "../mixins/adaptiveWatcher";

export default {
  name: "ServiceCard",

  props: ["selectedServices"],

  data: () => ({
    limitServices: 25,
    offsetServices: 0,
    countServices: 0,
    servicesList: [],
    serviceOther: null,
    // Search query
    servicesSearch: "",
    otherDisplaying: false,
    // For card displaying and adaptive view
    heigtWindow: 920,
    stepmobile: true,
    // For emiting data
    // selectedServices: "",
    // For watch on scroll
    scrollElem: null,

    isShowOtherTooltip: false,

    tooltipText: "Choose if you have not found your service in the list."
  }),
  mixins: [adaptivity],
  /* watch: {
    mobile () {
      this.stepmobile = true
    }
  }, */
  computed: {
    ...mapState("stepByStep", ["step1"]),
    searchServices: function() {
      /* 
      if (this.servicesSearch) {
        return this.servicesList.filter(function (services) {
          if (services.name.toLowerCase().includes(servicesSearch)) {
            return services
          }
        })
      } else {
        // return this.servicesList.filter(services => services.name !== 'Other')
        return this.servicesList
      } */

      return this.servicesList.filter(services => services.name !== "Other");
    },
    /* otherService () {
      return this.servicesList.filter(services => services.name === 'Other')
    }, */
    cardHeight() {
      if (window.innerHeight >= 900) {
        return this.heigtWindow * 0.68 + "px";
      } else {
        return this.heigtWindow * 0.55 + "px";
      }
    },

    innerWidth() {
      return window.innerWidth;
    }
  },
  methods: {
    ...mapMutations("stepByStep", [
      "step1Deactivate",
      "step2Activate",
      "step2Deactivate",
      "step3Deactivate"
    ]),
    // Step 1. Getting 15 services list
    async getServices() {
      try {
        const response = await GetSMSService.getService(
          this.limitServices,
          this.offsetServices
        );

        this.countServices = response.count;

        if (this.servicesList.length === 0) {
          this.servicesList = response.results;
        } else {
          this.servicesList = this.servicesList.concat(response.results);
        }
      } catch (error) {
        throw error;
      }
    },

    async getServiceOther() {
      try {
        const response = await GetSMSService.getServiceOther();

        this.serviceOther = response;
        // this.countServices = response.count;
      } catch (error) {
        throw error;
      }
    },

    async search() {
      let servicesSearch = this.servicesSearch.toLowerCase();
      let findServices = await GetSMSService.searchService(servicesSearch);
      if (findServices.length === 0) {
        this.otherDisplaying = true;
      } else {
        this.otherDisplaying = false;
      }
      this.servicesList = findServices;
      this.limitServices = 15;
      this.offsetServices = 0;
      this.countServices = 0;
    },
    setService(name) {
      this.servicesSearch = "";

      this.$emit("selectService", name);

      if (window.innerWidth <= 1024) {
        setTimeout(() => {
          this.step1Deactivate();
        }, 100);

        setTimeout(() => {
          this.step2Activate();
        }, 200);
      } else {
        this.step2Activate();
      }
    },
    clearService() {
      this.otherDisplaying = false;
      this.offsetServices = 0;
      this.servicesList = [];
      this.getServices();
      this.$emit("clear");
      this.step3Deactivate();
      this.step2Deactivate();
    }
  },
  created() {
    this.getServices();

    this.getServiceOther();
  },
  mounted() {
    this.heigtWindow = window.innerHeight;
    window.addEventListener("resize", this.handlerWindow);

    // this.scrollElem = document.querySelector("#servicesCard");
    this.scrollElem = this.$refs.servicesCard;

    this.scrollElem.addEventListener("scroll", e => {
      if (
        this.scrollElem.scrollTop + this.scrollElem.clientHeight + 10 >=
          this.scrollElem.scrollHeight &&
        !this.servicesSearch
      ) {
        if (this.offsetServices < this.countServices) {
          this.offsetServices = this.offsetServices + this.limitServices;
          this.getServices();
        }
      }
    });
  }
};
</script>