<template>
  <div class="top-bar">
    <div class="top-bar__left">
      <router-link to="/" class="logo logo_mobile">
        <img src="../assets/img/Logo.png" alt="SmsPlaza.io">
      </router-link>

      <button class="top-bar__menu-btn" @click.prevent="$emit('toggleNav')">
        <b-icon pack="fas" icon="bars"></b-icon>
      </button>
    </div>

    <div class="top-bar__right">
      <span class="top-bar__user-info">
        <b-icon pack="fas" icon="university"></b-icon>
        <span class="tablet-hidden">Balance:</span>
        &nbsp;
        <span class="yellow">&euro;{{ userInfo && userInfo.balance }}</span>
      </span>

      <span class="top-bar__user-info">
        <b-icon v-if="userInfo.loyal_points >= 7 " pack="far" icon="smile"></b-icon>
        <b-icon v-if="userInfo.loyal_points > 3 && userInfo.loyal_points < 7" pack="far" icon="meh"></b-icon>
        <b-icon v-if="userInfo.loyal_points <= 3" pack="far" icon="frown"></b-icon>
        <span class="tablet-hidden">Loyalty:</span>
        &nbsp;
        <span
          :class="{'green': userInfo.loyal_points >= 7, 
                    'yellow': userInfo.loyal_points >= 4, 
                    'red': userInfo.loyal_points <= 3 }"
        >{{ userInfo && userInfo.loyal_points }}/10</span>
      </span>

      <b-dropdown
        aria-role="list"
        :mobile-modal="false"
        position="is-bottom-left"
        class="custom-dropdown"
        :class="{'accent-color' : colorChangeDropdown}"
        @active-change="dropdown"
      >
        <span class="top-bar__user-info" slot="trigger" :class="{'z-index': z_i}">
          <b-icon pack="fas" icon="user" size="is-small" @click="dropdown"></b-icon>
          <span class="top-bar__account" :class="{'top-bar__account_rotate': openDropdown}">
            <span class="tablet-hidden">{{ userInfo && userInfo.email }}</span>
          </span>
        </span>

        <p class="custom-dropdown__tablet-visible tablet-visible">
          <b-icon pack="fas" icon="user" size="is-small"></b-icon>
          <span class="accent-second-color">{{ userInfo && userInfo.email }}</span>
        </p>

        <b-dropdown-item aria-role="listitem" @click="resetPasswod">Change password</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="logout">Log out</b-dropdown-item>
      </b-dropdown>
    </div>

    <b-modal :active.sync="changeModal" :width="450" scroll="keep">
      <div class="order-modal order-modal_buy">
        <h1 class="order-modal__title">Change Password</h1>
        <form @submit.prevent="submitHandler" class="auth-window__form auth-window__form_login">
          <div
            class="auth-window__form-input"
            :class="{
                'input_invalid' : ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength), 
                'input_valid' : !$v.password.$invalid
              }"
          >
            <input type="password" v-model.trim="$v.password.$model" placeholder="Curent Password">

            <small
              class="helper-text invalid"
              v-if="$v.password.$dirty && !$v.password.required"
            >This field must not be empty.</small>
            <!-- <small class="helper-text invalid" v-else-if="$v.password.$dirty && !$v.password.minLength">
                The field must be at least {{ $v.password.$params.minLength.min }} characters {{ password.length }}.
            </small>-->
          </div>

          <div
            class="auth-window__form-input"
            :class="{
                'input_invalid' : ($v.newpassword1.$dirty && !$v.newpassword1.required) || ($v.newpassword1.$dirty && !$v.newpassword1.minLength), 
                'input_valid' : !$v.newpassword1.$invalid
              }"
          >
            <input type="password" v-model.trim="$v.newpassword1.$model" placeholder="New Password">
            <small
              class="helper-text invalid"
              v-if="$v.newpassword1.$dirty && !$v.newpassword1.required"
            >This field must not be empty</small>
            <small
              class="helper-text invalid"
              v-else-if="$v.newpassword1.$dirty && !$v.newpassword1.minLength"
            >The field must be at least {{ $v.newpassword1.$params.minLength.min }} characters. {{ newpassword1.length }}</small>
          </div>

          <div
            class="auth-window__form-input"
            :class="{
                'input_invalid' : ($v.newpassword2.$dirty && !$v.newpassword2.required) || ($v.newpassword2.$dirty && !$v.newpassword2.sameAsPassword), 
                'input_valid' : !$v.newpassword2.$invalid
              }"
          >
            <input type="password" v-model.trim="$v.newpassword2.$model" placeholder="New Password">
            <small
              class="helper-text invalid"
              v-if="$v.newpassword2.$dirty && !$v.newpassword2.required"
            >This field must not be empty.</small>
            <small
              class="helper-text invalid"
              v-else-if="$v.newpassword2.$dirty && !$v.newpassword2.sameAsPassword"
            >This field does not match the field password.</small>
          </div>

          <button class="order-modal__button">Change Password</button>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { GetSMSService } from "../services/dashboard.services";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  data: () => ({
    user: "",
    loyalty: "",
    balance: "",
    openDropdown: false,
    changeModal: false,
    password: "",
    newpassword1: "",
    newpassword2: "",
    colorChangeDropdown: false,
    z_i: false
  }),
  validations: {
    password: { required, minLength: minLength(1) },
    newpassword1: { required, minLength: minLength(8) },
    newpassword2: { required, sameAsPassword: sameAs("newpassword1") }
  },
  computed: {
    ...mapState("auth", ["changePassError", "userInfo"])
  },
  methods: {
    ...mapMutations("auth", ["clearChangePassError"]),
    ...mapActions("auth", ["logout"]),
    dropdown() {
      this.openDropdown = !this.openDropdown;
      this.colorChangeDropdown = !this.colorChangeDropdown;
      this.z_i = !this.z_i;
    },
    resetPasswod() {
      this.changeModal = !this.changeModal;
    },
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      const cnangedPass = {
        old_password: this.password,
        new_password1: this.newpassword1,
        new_password2: this.newpassword2
      };

      await this.$store.dispatch("auth/changePassword", cnangedPass);

      if (this.changePassError) {
        this.errorHandling();
        this.clearChangePassError();
        // this.clearForm()
      } else {
        this.$toasted.show("Your password successfully changed", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 10000,
          className: "success-notification"
        });
        this.changeModal = false;
      }
    },
    errorHandling() {
      let errorObj = this.changePassError;

      for (let key in errorObj) {
        for (let i = 0; i < errorObj[key].length; i++) {
          this.$toasted.show(errorObj[key][i], {
            theme: "toasted-primary",
            position: "top-right",
            duration: 10000,
            className: "danger-notification"
          });
        }
      }
    }
    /* clearForm () {
      this.password = ''
      this.newpassword1 = ''
      this.newpassword2 = ''
    } */
  },
  async created() {
    try {
      const response = await GetSMSService.getUserInfo();

      this.user = response.email;
      this.loyalty = response.loyal_points;
      this.balance = response.balance;
    } catch (error) {
      throw error;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-index {
  z-index: 21;
}
</style>
