<template>
  <div class="get-sms-page">
    <div class="sms-chose">
      <!-- <pre>{{ selectedService }}</pre> -->

      <!-- <p>{{ step3 }}</p> -->

      <zoom-center-transition :delay="50" :duration="200">
        <services
          v-if="step1"
          :selectedServices="selectedService"
          @selectService="setServices"
          @clear="clearService"
        />
      </zoom-center-transition>

      <zoom-center-transition :delay="50" :duration="200">
        <countries
          v-if="step2"
          :data="countryList"
          :selectedCountries="selectedCountry"
          :service="serviceName"
          @selectCountry="setCountry"
          @load="loadMoreCountries"
          @clear="clearNumber"
          @changeService="clearService"
        />
      </zoom-center-transition>

      <zoom-center-transition :delay="50" :duration="200">
        <numbers
          v-if="step3"
          :data="numberList"
          :code="codeCountry"
          @selectNumber="getOrder"
          @load="loadMoreNumbers"
          @changeCountry="clearNumber"
        />
      </zoom-center-transition>

      <b-modal
        class="order-modal-container"
        :active.sync="isCardModalActive"
        :width="520"
        scroll="keep"
      >
        <div class="order-modal">
          <h1 class="order-modal__title">Step 4. Confirm order</h1>
          <ul class="order-modal__order-list">
            <li class="order-modal__order-list-item">
              <span class="order-modal__order-info">Service:</span>

              <div class="card__content">
                <span class="card__list-icon card__list-icon_order" v-if="order.services_icon">
                  <img :src="order.services_icon" :alt="order.services">
                </span>
                <span class="order-value">{{order.services}}</span>
              </div>
            </li>

            <li class="order-modal__order-list-item">
              <span class="order-modal__order-info">Number:</span>

              <div class="card__content">
                <span class="order-value">
                  <span class="flag-icon" :class="`flag-icon-${order.country_flag}`"></span>
                  {{order.number}}
                </span>
              </div>
            </li>

            <li class="order-modal__order-list-item order-modal__order-list-item_total">
              <span class="order-modal__order-info">total</span>

              <div class="card__content">
                <span class="order-value price">€{{order.price}}</span>
              </div>
            </li>
          </ul>
          <button class="order-modal__button" @click="buySMS">Create</button>

          <!-- <transition name="fade" v-if="errorSms">
            <p class="order-modal__error">{{errorSms}}</p>
          </transition>-->
          <AppError :error="errorSms"/>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Services from "../components/ServiceCard";
import Countries from "../components/CountryCard";
import Numbers from "../components/NumbersCard";

import AppError from "@/components/app/root/AppError";

import { GetSMSService } from "../services/dashboard.services";

import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "getSMS",
  components: {
    AppError,

    Services,
    Countries,
    Numbers
  },
  data: () => ({
    // Data from API
    limitCoutry: 15,
    offsetCoutry: 0,
    countCoutry: 0,
    countryList: [],
    codeCountry: "",
    limitNumbers: 15,
    offsetNumbers: 0,
    countNumbers: 0,
    numberList: [],
    // Modal window state
    isCardModalActive: false,
    // For buy number
    selectedService: null,
    selectedCountry: null,
    selectedNumber: null,
    order: {},
    // Error
    errorSms: null
  }),
  computed: {
    ...mapState("stepByStep", ["step1", "step2", "step3"]),

    serviceName() {
      if (this.selectedService) {
        return this.selectedService.name;
      }
    }
  },
  methods: {
    ...mapMutations("stepByStep", [
      "step1Activate",
      "step2Activate",
      "step2Deactivate",
      "step3Activate",
      "step3Deactivate"
    ]),

    handleAnimate() {
      let elem = document.querySelector(".animation-content");
      if (!elem.classList.contains("errorAminate")) {
        elem.classList.add("errorAminate");
      }
      setTimeout(() => {
        elem.classList.remove("errorAminate");
      }, 1300);
    },
    // Step 2. Getting all country list after service choosing
    setServices(value) {
      if (!this.selectedService || value.id != this.selectedService.id) {
        this.countryList = [];
        this.offsetCoutry = 0;

        this.selectedService = value;
        this.getCountry();
      }
    },
    async getCountry() {
      try {
        const response = await GetSMSService.getCountries(
          this.selectedService,
          this.limitCoutry,
          this.offsetCoutry
        );
        this.countCoutry = response.count;

        if (this.countryList.length === 0) {
          this.countryList = response.results;
        } else {
          this.countryList = this.countryList.concat(response.results);
        }
      } catch (error) {
        throw error;
      }
    },
    cnangeCountryMobile() {
      // this.mobileView2 = !this.mobileView2

      this.numberList = [];
      this.selectedCountry = [];
      this.codeCountry = "";
      this.limitNumbers = 15;
      this.offsetNumbers = 0;
      this.countNumbers = 0;
    },
    loadMoreCountries() {
      if (this.offsetCoutry < this.countCoutry) {
        this.offsetCoutry = this.offsetCoutry + this.limitCoutry;
        this.getCountry();
      }
    },
    // Step 3. Getting all numbers for select service and country
    setCountry(value) {
      this.selectedCountry = value;
      this.getNumbers();
    },
    async getNumbers(search, sort) {
      try {
        const response = await GetSMSService.getNumbers(
          this.selectedService,
          this.selectedCountry,
          this.limitNumbers,
          this.offsetNumbers,
          search,
          sort
        );
        this.countNumbers = response.count;

        if (this.numberList.length === 0) {
          this.numberList = response.results;
        } else {
          this.numberList = this.numberList.concat(response.results);
        }

        // Country code for numbers search, pass to prop
        if (this.numberList.length !== 0) {
          let code = this.numberList[this.numberList.length - 1].number_str;
          let position = code.indexOf("*");
          this.codeCountry = code.slice(0, position);
        } else {
          this.codeCountry = "";
        }
      } catch (error) {
        throw error;
      }
    },
    loadMoreNumbers({ type, number, sort }) {
      if (type === "search" || type === "sort") {
        this.offsetNumbers = 0;
        this.numberList = [];

        this.getNumbers(number, sort);
      } else {
        if (this.offsetNumbers < this.countNumbers) {
          this.offsetNumbers = this.offsetNumbers + this.limitNumbers;
          this.getNumbers(number, sort);
        }
      }
    },
    // Info for modal window before buy number
    getOrder(value) {
      this.selectedNumber = value;

      this.order.services = this.selectedService.name;
      this.order.services_icon = this.selectedService.icon;

      this.order.country = this.selectedCountry.name;
      this.order.country_flag = this.selectedCountry.flag;
      this.order.price = this.selectedCountry.price;

      this.order.number = this.selectedNumber.number_str;
      this.order.number_id = this.selectedNumber.id;

      this.isCardModalActive = true;
    },
    // Buy number
    async buySMS() {
      let buyData = {
        service: this.selectedService.id,
        phone_number: this.order.number_id
      };

      try {
        const response = await GetSMSService.buySms(buyData);

        this.isCardModalActive = false;

        this.$toasted.show(`Your purchase is successful.`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 5000,
          className: "success-notification"
        });

        this.$router.push("/orders");
      } catch (error) {
        if (
          error.message.generic_error[0] ==
          "You don't have enough money on your balance"
        )
          this.errorSms = "You have insufficient funds. Make a deposit.";
        else this.errorSms = error.message.generic_error[0];

        this.handleAnimate();
        // this.$toasted.show(`Error. ${this.errorSms}`, {
        //   theme: "toasted-primary",
        //   position: "top-right",
        //   duration: 5000,
        //   className: "danger-notification"
        // });
        // this.isCardModalActive = false;
        // this.selectedCountry = null;
        // this.selectedService = null;
        // throw error;
      }
    },
    // Clear selected country and number in cards
    clearService() {
      this.selectedService = "";
      this.selectedCountry = "";
      this.countryList = [];
      this.numberList = [];
      this.limitCoutry = 15;
      this.offsetCoutry = 0;
      this.countCoutry = 0;

      this.limitNumbers = 20;
      this.offsetNumbers = 0;
      this.countNumbers = 0;
    },
    clearCountry() {
      this.selectedCountry = "";
      this.countryList = [];
      this.codeCountry = "";
      this.limitNumbers = 15;
      this.offsetNumbers = 0;
      this.countNumbers = 0;
    },

    // Clear number list in card for 2 step
    clearNumber() {
      this.selectedCountry = "";
      this.numberList = [];
      // this.selectedCountry = [];
      this.codeCountry = "";
      this.limitNumbers = 15;
      this.offsetNumbers = 0;
      this.countNumbers = 0;
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {
    this.clearService();
    this.clearNumber();

    this.step1Activate();
    this.step2Deactivate();
    this.step3Deactivate();
  }
};
</script>


