var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-bar"},[_c('div',{staticClass:"top-bar__left"},[_c('router-link',{staticClass:"logo logo_mobile",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/img/Logo.png"),"alt":"SmsPlaza.io"}})]),_c('button',{staticClass:"top-bar__menu-btn",on:{"click":function($event){$event.preventDefault();return _vm.$emit('toggleNav')}}},[_c('b-icon',{attrs:{"pack":"fas","icon":"bars"}})],1)],1),_c('div',{staticClass:"top-bar__right"},[_c('span',{staticClass:"top-bar__user-info"},[_c('b-icon',{attrs:{"pack":"fas","icon":"university"}}),_c('span',{staticClass:"tablet-hidden"},[_vm._v("Balance:")]),_vm._v("\n       \n      "),_c('span',{staticClass:"yellow"},[_vm._v("€"+_vm._s(_vm.userInfo && _vm.userInfo.balance))])],1),_c('span',{staticClass:"top-bar__user-info"},[(_vm.userInfo.loyal_points >= 7 )?_c('b-icon',{attrs:{"pack":"far","icon":"smile"}}):_vm._e(),(_vm.userInfo.loyal_points > 3 && _vm.userInfo.loyal_points < 7)?_c('b-icon',{attrs:{"pack":"far","icon":"meh"}}):_vm._e(),(_vm.userInfo.loyal_points <= 3)?_c('b-icon',{attrs:{"pack":"far","icon":"frown"}}):_vm._e(),_c('span',{staticClass:"tablet-hidden"},[_vm._v("Loyalty:")]),_vm._v("\n       \n      "),_c('span',{class:{'green': _vm.userInfo.loyal_points >= 7, 
                  'yellow': _vm.userInfo.loyal_points >= 4, 
                  'red': _vm.userInfo.loyal_points <= 3 }},[_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.loyal_points)+"/10")])],1),_c('b-dropdown',{staticClass:"custom-dropdown",class:{'accent-color' : _vm.colorChangeDropdown},attrs:{"aria-role":"list","mobile-modal":false,"position":"is-bottom-left"},on:{"active-change":_vm.dropdown}},[_c('span',{staticClass:"top-bar__user-info",class:{'z-index': _vm.z_i},attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"pack":"fas","icon":"user","size":"is-small"},on:{"click":_vm.dropdown}}),_c('span',{staticClass:"top-bar__account",class:{'top-bar__account_rotate': _vm.openDropdown}},[_c('span',{staticClass:"tablet-hidden"},[_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.email))])])],1),_c('p',{staticClass:"custom-dropdown__tablet-visible tablet-visible"},[_c('b-icon',{attrs:{"pack":"fas","icon":"user","size":"is-small"}}),_c('span',{staticClass:"accent-second-color"},[_vm._v(_vm._s(_vm.userInfo && _vm.userInfo.email))])],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.resetPasswod}},[_vm._v("Change password")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.logout}},[_vm._v("Log out")])],1)],1),_c('b-modal',{attrs:{"active":_vm.changeModal,"width":450,"scroll":"keep"},on:{"update:active":function($event){_vm.changeModal=$event}}},[_c('div',{staticClass:"order-modal order-modal_buy"},[_c('h1',{staticClass:"order-modal__title"},[_vm._v("Change Password")]),_c('form',{staticClass:"auth-window__form auth-window__form_login",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}}},[_c('div',{staticClass:"auth-window__form-input",class:{
              'input_invalid' : (_vm.$v.password.$dirty && !_vm.$v.password.required) || (_vm.$v.password.$dirty && !_vm.$v.password.minLength), 
              'input_valid' : !_vm.$v.password.$invalid
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],attrs:{"type":"password","placeholder":"Curent Password"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.password.$dirty && !_vm.$v.password.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v("This field must not be empty.")]):_vm._e()]),_c('div',{staticClass:"auth-window__form-input",class:{
              'input_invalid' : (_vm.$v.newpassword1.$dirty && !_vm.$v.newpassword1.required) || (_vm.$v.newpassword1.$dirty && !_vm.$v.newpassword1.minLength), 
              'input_valid' : !_vm.$v.newpassword1.$invalid
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.newpassword1.$model),expression:"$v.newpassword1.$model",modifiers:{"trim":true}}],attrs:{"type":"password","placeholder":"New Password"},domProps:{"value":(_vm.$v.newpassword1.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.newpassword1, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.newpassword1.$dirty && !_vm.$v.newpassword1.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v("This field must not be empty")]):(_vm.$v.newpassword1.$dirty && !_vm.$v.newpassword1.minLength)?_c('small',{staticClass:"helper-text invalid"},[_vm._v("The field must be at least "+_vm._s(_vm.$v.newpassword1.$params.minLength.min)+" characters. "+_vm._s(_vm.newpassword1.length))]):_vm._e()]),_c('div',{staticClass:"auth-window__form-input",class:{
              'input_invalid' : (_vm.$v.newpassword2.$dirty && !_vm.$v.newpassword2.required) || (_vm.$v.newpassword2.$dirty && !_vm.$v.newpassword2.sameAsPassword), 
              'input_valid' : !_vm.$v.newpassword2.$invalid
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.newpassword2.$model),expression:"$v.newpassword2.$model",modifiers:{"trim":true}}],attrs:{"type":"password","placeholder":"New Password"},domProps:{"value":(_vm.$v.newpassword2.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.newpassword2, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.newpassword2.$dirty && !_vm.$v.newpassword2.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v("This field must not be empty.")]):(_vm.$v.newpassword2.$dirty && !_vm.$v.newpassword2.sameAsPassword)?_c('small',{staticClass:"helper-text invalid"},[_vm._v("This field does not match the field password.")]):_vm._e()]),_c('button',{staticClass:"order-modal__button"},[_vm._v("Change Password")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }